<template>
  <div>
    <notifications group="notification" position="top right" />
    <div class="row">
      <div class="col-12">
        <div class="card card-custom card-sticky">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">
                <span class="svg-icon svg-icon-primary svg-icon-2x">
                  <inline-svg src="media/svg/icons/Map/Position.svg" />
                </span>
              </h3>
            </div>
            <div class="card-toolbar">
              <router-link
                to="/locations/list"
                class="btn btn-light-primary font-weight-bolder mr-2"
              >
                <i class="ki ki-long-arrow-back icon-sm"></i>
                All Locations
              </router-link>
              <div class="js-submit-options btn-group">
                <button
                  @click="postEntity"
                  type="button"
                  class="btn btn-primary font-weight-bolder"
                >
                  <i class="ki ki-check icon-sm"></i>
                  Edit Location
                </button>
              </div>
            </div>
          </div>
          <div v-if="isLoaded" class="card-body">
            <div class="row">
              <div class="col-xl-2"></div>
              <div class="col-xl-8">
                <div class="my-5">
                  <h3 class="text-dark font-weight-bold mb-10">Info:</h3>
                  <div class="form-group row d-flex align-items-center">
                    <label class="col-3">City:</label>
                    <div class="col-9">
                      <vSelect
                        v-model="location.city"
                        class="form-control form-control-solid form-control--city-select"
                        placeholder="Odaberite grad"
                        :options="cities"
                        :reduce="(city) => city['@id']"
                        label="name"
                      />
                    </div>
                  </div>
                  <div
                    v-if="currentUserCompanyModule[0] === 'gaming'"
                    class="form-group row d-flex align-items-center"
                  >
                    <label class="col-3">Lessor:</label>
                    <div class="col-6">
                      <vSelect
                        v-model="location.lessor"
                        class="form-control form-control-solid form-control--city-select"
                        placeholder="Select lessor"
                        :options="lessors"
                        :reduce="(lessor) => lessor['@id']"
                        label="name"
                      />
                    </div>
                    <label class="col-2">
                      <button
                        @click="removeLocationLessor"
                        class="btn btn-danger"
                      >
                        Remove
                      </button>
                    </label>
                  </div>
                  <div class="d-flex align-items-center form-group row" style="gap: 16px;">
                    <label class="col-3">Location Active:</label>
                    <div class="d-flex align-items-center" style="gap: 16px;">
                      <div class="d-flex align-items-start" style="gap: 4px;">
                        <input 
                          type="radio"
                          id="locationActiveYes" 
                          value="true" 
                          v-model="location.isActive"
                        >
                        <label for="locationActiveYes">Yes</label>
                      </div>
                      <div class="d-flex align-items-start" style="gap: 4px;">
                        <input 
                          type="radio" 
                          id="locationActiveNo" 
                          value="false" 
                          v-model="location.isActive"
                        >
                        <label for="locationActiveNo">No</label>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex align-items-center form-group row">
                    <label class="col-3">Location name:</label>
                    <div class="col-9">
                      <input
                        v-model="location.title"
                        class="form-control form-control-solid"
                        type="text"
                        placeholder="npr. City Centar ONE Split"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-3">Description:</label>
                    <div class="col-9">
                      <textarea
                        v-model="location.description"
                        class="form-control form-control-solid"
                        type="text"
                        rows="5"
                      ></textarea>
                    </div>
                  </div>
                  <div class="form-group row d-flex align-items-center">
                    <label class="col-3">Responsible worker:</label>
                    <div class="col-9">
                      <input
                        v-model="location.responsibleWorker"
                        class="form-control form-control-solid"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div class="my-5" style="width: 100%;">
                  <h3 class="text-dark font-weight-bold mb-10">Working Time:</h3>
                  <div class="mb-10">
                    <label class="text-capitalize mb-10">Monday:</label>
                    <div class="form-group row d-flex align-items-center">
                      <label class="col-3 text-capitalize">Start Time:</label>
                      <div class="col-4">
                        <input
                          v-model="location.mondayStartTime"
                          class="form-control form-control-solid"
                          type="time"
                          placeholder="Start time"
                        />
                      </div>
                    </div>
                    <div class="form-group row d-flex align-items-center">
                      <label class="col-3 text-capitalize">End Time:</label>
                      <div class="col-4">
                        <input
                          v-model="location.mondayEndTime"
                          class="form-control form-control-solid"
                          type="time"
                          placeholder="End time"
                        />
                      </div>
                    </div>
                  </div>
                 <div class="mb-10">
                    <label class="mb-10 text-dark font-weigth-medium text-capitalize">Tuesday:</label>
                    <div class="form-group row d-flex align-items-center">
                      <label class="col-3 text-capitalize">Start Time:</label>
                      <div class="col-4">
                        <input
                          v-model="location.tuesdayStartTime"
                          class="form-control form-control-solid"
                          type="time"
                          placeholder="Start time"
                        />
                      </div>
                    </div>
                    <div class="form-group row d-flex align-items-center">
                      <label class="col-3 text-capitalize">End Time:</label>
                      <div class="col-4">
                        <input
                          v-model="location.tuesdayEndTime"
                          class="form-control form-control-solid"
                          type="time"
                          placeholder="End time"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="mb-10">
                    <label class="mb-10 text-dark font-weigth-medium text-capitalize">Wednesday:</label>
                    <div class="form-group row d-flex align-items-center">
                      <label class="col-3 text-capitalize">Start Time:</label>
                      <div class="col-4">
                        <input
                          v-model="location.wednesdayStartTime"
                          class="form-control form-control-solid"
                          type="time"
                          placeholder="Start time"
                        />
                      </div>
                    </div>
                    <div class="form-group row d-flex align-items-center">
                      <label class="col-3 text-capitalize">End Time:</label>
                      <div class="col-4">
                        <input
                          v-model="location.wednesdayEndTime"
                          class="form-control form-control-solid"
                          type="time"
                          placeholder="End time"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="mb-10">
                    <label class="mb-10 text-dark font-weigth-medium text-capitalize">Thursday:</label>
                    <div class="form-group row d-flex align-items-center">
                      <label class="col-3 text-capitalize">Start Time:</label>
                      <div class="col-4">
                        <input
                          v-model="location.thursdayStartTime"
                          class="form-control form-control-solid"
                          type="time"
                          placeholder="Start time"
                        />
                      </div>
                    </div>
                    <div class="form-group row d-flex align-items-center">
                      <label class="col-3 text-capitalize">End Time:</label>
                      <div class="col-4">
                        <input
                          v-model="location.thursdayEndTime"
                          class="form-control form-control-solid"
                          type="time"
                          placeholder="End time"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="mb-10">
                    <label class="mb-10 text-dark font-weigth-medium text-capitalize">Friday:</label>
                    <div class="form-group row d-flex align-items-center">
                      <label class="col-3 text-capitalize">Start Time:</label>
                      <div class="col-4">
                        <input
                          v-model="location.fridayStartTime"
                          class="form-control form-control-solid"
                          type="time"
                          placeholder="Start time"
                        />
                      </div>
                    </div>
                    <div class="form-group row d-flex align-items-center">
                      <label class="col-3 text-capitalize">End Time:</label>
                      <div class="col-4">
                        <input
                          v-model="location.fridayEndTime"
                          class="form-control form-control-solid"
                          type="time"
                          placeholder="End time"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="mb-10">
                    <label class="mb-10 text-dark font-weigth-medium text-capitalize">Saturday:</label>
                    <div class="form-group row d-flex align-items-center">
                      <label class="col-3 text-capitalize">Start Time:</label>
                      <div class="col-4">
                        <input
                          v-model="location.saturdayStartTime"
                          class="form-control form-control-solid"
                          type="time"
                          placeholder="Start time"
                        />
                      </div>
                    </div>
                    <div class="form-group row d-flex align-items-center">
                      <label class="col-3 text-capitalize">End Time:</label>
                      <div class="col-4">
                        <input
                          v-model="location.saturdayEndTime"
                          class="form-control form-control-solid"
                          type="time"
                          placeholder="End time"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="mb-10">
                    <label class="mb-10 text-dark font-weigth-medium text-capitalize">Sunday:</label>
                    <div class="form-group row d-flex align-items-center">
                      <label class="col-3 text-capitalize">Start Time:</label>
                      <div class="col-4">
                        <input
                          v-model="location.sundayStartTime"
                          class="form-control form-control-solid"
                          type="time"
                          placeholder="Start time"
                        />
                      </div>
                    </div>
                    <div class="form-group row d-flex align-items-center">
                      <label class="col-3 text-capitalize">End Time:</label>
                      <div class="col-4">
                        <input
                          v-model="location.sundayEndTime"
                          class="form-control form-control-solid"
                          type="time"
                          placeholder="End time"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="separator separator-dashed my-10"></div>
                <div class="my-5">
                  <h3 class="text-dark font-weight-bold mb-10">Address:</h3>
                  <div class="form-group row">
                    <label class="col-3">Street name:</label>
                    <div class="col-9">
                      <input
                        v-model="location.address.name"
                        class="form-control form-control-solid"
                        type="text"
                        placeholder="npr. Slavenskog 1, 10110 Zagreb"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-3">Lat:</label>
                    <div class="col-9">
                      <input
                        v-model="location.address.lat"
                        class="form-control form-control-solid"
                        type="number"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-3">Lng:</label>
                    <div class="col-9">
                      <input
                        v-model="location.address.lng"
                        class="form-control form-control-solid"
                        type="number"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-3">Map:</label>
                    <div class="col-9">
                      <div style="height: 350px">
                        <l-map
                          v-if="showMap"
                          :zoom="zoom"
                          :center="center"
                          :options="mapOptions"
                          @update:center="centerUpdate"
                          @update:zoom="zoomUpdate"
                          @click="addMarker"
                        >
                          <l-tile-layer :url="url" :attribution="attribution" />
                          <l-marker
                            v-for="(coordinate, key) in coordinates"
                            :key="key"
                            :lat-lng="coordinate"
                            @click="removeMarker(index)"
                          ></l-marker>
                        </l-map>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-2"></div>
            </div>
          </div>
          <div v-else class="col-12 text-center py-5">
            <b-spinner variant="primary" label="Spinning"></b-spinner>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import ApiService from '@/core/services/api.service'
import { mapGetters } from 'vuex'
import { Icon, latLng } from 'leaflet'
import { LMap, LMarker, LTileLayer } from 'vue2-leaflet'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'

delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

export default {
  name: 'LocationsEdit',
  components: {
    vSelect,
    LMap,
    LTileLayer,
    LMarker,
  },
  data() {
    return {
      location: {
        mondayStartTime: null,
        mondayEndTime: null,
        tuesdayStartTime: null,
        tuesdayEndTime: null,
        wednesdayStartTime: null,
        wednesdayEndTime: null,
        thursdayStartTime: null,
        thursdayEndTime: null,
        fridayStartTime: null,
        fridayEndTime: null,
        saturdayStartTime: null,
        saturdayEndTime: null,
        sundayStartTime: null,
        sundayEndTime: null,
        title: '',
        description: '',
        address: {
          name: '',
          lat: null,
          lng: null,
        },
        isActive: true,
        responsibleWorker: null,
        city: '',
        company: '',
        lessor: null,
      },
      cities: [],
      lessors: [],
      accessToken:
        'pk.eyJ1IjoibmdyZGFuanNraTg4IiwiYSI6ImNrZm13ajlpZzA2ejUycW9majR1cXN3aXUifQ.elfRLuIZlHb5RZG0FL0sGg',
      isMarkerSet: false,
      zoom: 7,
      center: latLng(44.709554, 16.1646569),
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      currentZoom: 5,
      showParagraph: false,
      mapOptions: {
        zoomSnap: 0.5,
      },
      showMap: true,
      coordinates: [],
      isLoaded: false
    }
  },
  computed: {
    ...mapGetters([
      'currentUserRole',
      'currentUserCompany',
      'currentUserCompanyModule',
    ]),
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Edit Location', route: '' },
    ])
    ApiService.get('locations', this.$route.params.id).then(({ data }) => {
      this.location.title = data.title
      this.location.description = data.description
      this.location.responsibleWorker = data.responsibleWorker
      this.location.address.name = data.address.name
      this.location.address.lat = data.address.lat
      this.location.address.lng = data.address.lng
      this.location.isActive = data.isActive
      this.location.city = data.city
      this.location.company = data.company
      this.location.lessor = data.lessor

      if(data.mondayStartTime) {
        this.location.mondayStartTime = this.formatTime(data.mondayStartTime) 
      }

      if(data.mondayEndTime) {
        this.location.mondayEndTime = this.formatTime(data.mondayEndTime)
      }

      if(data.tuesdayStartTime) {
        this.location.tuesdayStartTime = this.formatTime(data.tuesdayStartTime) 
      }

      if(data.tuesdayEndTime) {
        this.location.tuesdayEndTime = this.formatTime(data.tuesdayEndTime)
      }

      if(data.wednesdayStartTime) {
        this.location.wednesdayStartTime = this.formatTime(data.wednesdayStartTime) 
      }

      if(data.wednesdayEndTime) {
        this.location.wednesdayEndTime = this.formatTime(data.wednesdayEndTime)
      }

      if(data.thursdayStartTime) {
        this.location.thursdayStartTime = this.formatTime(data.thursdayStartTime) 
      }

      if(data.thursdayEndTime) {
        this.location.thursdayEndTime = this.formatTime(data.thursdayEndTime)
      }

      if(data.fridayStartTime) {
        this.location.fridayStartTime = this.formatTime(data.fridayStartTime) 
      }

      if(data.fridayEndTime) {
        this.location.fridayEndTime = this.formatTime(data.fridayEndTime)
      }

      if(data.saturdayStartTime) {
        this.location.saturdayStartTime = this.formatTime(data.saturdayStartTime) 
      }

      if(data.saturdayEndTime) {
        this.location.saturdayEndTime = this.formatTime(data.saturdayEndTime)
      }

      if(data.sundayStartTime) {
        this.location.sundayStartTime = this.formatTime(data.sundayStartTime) 
      }

      if(data.sundayEndTime) {
        this.location.sundayEndTime = this.formatTime(data.sundayEndTime)
      }

      this.coordinates.push({
        lat: data.address.lat,
        lng: data.address.lng,
      })

      ApiService.get('cities', '?pagination=false').then(({ data }) => {
      this.cities = [...data['hydra:member']]
      this.isLoaded = true
    })

    ApiService.get('lessors', '?pagination=false').then(({ data }) => {
      this.lessors = [...data['hydra:member']]
      this.isLoaded = true
    })
    })
  },
  methods: {
    formatTime(isoString) {
      if (!isoString) return null;
      
      const timePart = isoString.split('T')[1];
      const timeWithoutOffset = timePart.split('+')[0].split('-')[0];
      
      const [hours, minutes] = timeWithoutOffset.split(':');
      
      return `${hours}:${minutes}`;
    },
    parseEmptyString(value) {
      return value === '' ? null : value;
    },
    isValidTimeRange(startTime, endTime) {
      if (!startTime || !endTime) return true; // Only validate if both times are present
      return startTime < endTime;
    },
    validateTimes() {
      const days = [
        'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'
      ];

      for (const day of days) {
        let startTime = this.parseEmptyString(this.location[`${day}StartTime`]);
        let endTime = this.parseEmptyString(this.location[`${day}EndTime`]);

        // Check if both start and end times are present or both are absent
        if ((startTime && !endTime) || (!startTime && endTime)) {
          this.$notify({
            group: 'notification',
            type: 'error',
            title: `Both start time and end time must be provided for ${day.charAt(0).toUpperCase() + day.slice(1)}.`,
          });
          return false;
        }

        // Check if end time is after start time
        if (!this.isValidTimeRange(startTime, endTime)) {
          this.$notify({
            group: 'notification',
            type: 'error',
            title: `End time for ${day.charAt(0).toUpperCase() + day.slice(1)} must be after start time.`,
          });
          return false;
        }
      }

      return true;
    },
    removeLocationLessor() {
      this.location.lessor = null
    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom
    },
    centerUpdate(center) {
      this.currentCenter = center
    },
    addMarker(event) {
      this.location.address.lat = event.latlng.lat
      this.location.address.lng = event.latlng.lng
      this.coordinates.push(event.latlng)
    },
    removeMarker(index) {
      this.coordinates.splice(index, 1)
    },
    postEntity() {
       if (!this.validateTimes()) {
        return;
      }
      const formattedLocation = {
        ...this.location,
        mondayStartTime: this.parseEmptyString(this.location.mondayStartTime),
        mondayEndTime: this.parseEmptyString(this.location.mondayEndTime),
        tuesdayStartTime: this.parseEmptyString(this.location.tuesdayStartTime),
        tuesdayEndTime: this.parseEmptyString(this.location.tuesdayEndTime),
        wednesdayStartTime: this.parseEmptyString(this.location.wednesdayStartTime),
        wednesdayEndTime: this.parseEmptyString(this.location.wednesdayEndTime),
        thursdayStartTime: this.parseEmptyString(this.location.thursdayStartTime),
        thursdayEndTime: this.parseEmptyString(this.location.thursdayEndTime),
        fridayStartTime: this.parseEmptyString(this.location.fridayStartTime),
        fridayEndTime: this.parseEmptyString(this.location.fridayEndTime),
        saturdayStartTime: this.parseEmptyString(this.location.saturdayStartTime),
        saturdayEndTime: this.parseEmptyString(this.location.saturdayEndTime),
        sundayStartTime: this.parseEmptyString(this.location.sundayStartTime),
        sundayEndTime: this.parseEmptyString(this.location.sundayEndTime),
        isActive: String(this.location.isActive) === 'false' ? false : true
      };
      ApiService.update('locations', this.$route.params.id, formattedLocation)
        .then(() => {
          this.$notify({
            group: 'notification',
            type: 'success',
            title: 'Location successfully updated in database.',
          })

          setTimeout(() => {
            this.$router.push({ name: 'locations-list' })
          }, 500)
        })
        .catch(() => {
          this.$notify({
            group: 'notification',
            type: 'error',
            title: 'Failed to update location.',
          });
        })
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/sass/pages/wizard/wizard-2.scss';
@import 'vue-select/src/scss/vue-select.scss';

.basemap {
  width: 100%;
  height: 300px;
}
.form-control {
  &--city-select {
    height: unset !important;

    .vs__dropdown-toggle {
      padding: 0;
      border: 0;
      border-radius: 0;
    }
    .vs__dropdown-menu {
      border: 1px solid #e4e6ef;
      -webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
      box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);

      /* width */
      &::-webkit-scrollbar {
        width: 4px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      li {
        padding: 0.65rem 1rem;

        &:hover {
          background: #f3f6f9;
          color: #3f4254;
        }
      }
    }

    .vs__clear {
      display: none;
    }
    .vs__selected {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0.25rem 0.75rem;
      font-size: 1rem;
      margin: 0.1rem 0.4rem 0.1rem 0;
      position: relative;
      float: left;
      color: #3f4254;
      background: #f3f6f9;
      border: 0;
      border-radius: 0.42rem;
    }
    .vs__dropdown-option--highlight {
      background: #ebedf3;
      color: #3f4254;
    }
  }
}
</style>
